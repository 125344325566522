"use strict";
const agent = require("./agent");

function estimateProfitMargin(platforms, codes) {
	return agent
		.get()
		.post("/api/price/profit-margin/estimate", { platforms, codes })
		.then(response => response.data.data);
}

function getAdvises(queries) {
	return agent
		.get()
		.get("/api/price/advise", { params: queries })
		.then(response => response.data);
}

function update(updates) {
	return agent
		.get()
		.patch("/api/price", { payload: updates })
		.then(response => response.data);
}

function requestExportEstimation(request) {
	return agent
		.get()
		.post("/api/price/estimation/export", { request })
		.then(response => response.data.data);
}

function getExportStatus(payload) {
	return agent
		.get()
		.get("/api/price/estimation/export", { params: payload })
		.then(response => response.data);
}

function deleteEstimateRequest(id) {
	return agent
		.get()
		.delete("/api/price/estimation/export/" + id)
		.then(response => response.data);
}

function getNewPriceAdvisorData(params) {
	return agent
		.get()
		.post("/api/price/advisor/new", {params})	
		.then(response => response.data);
}

function getPlatformFromPriceTable(params) {
	return agent
		.get()
		.get("/api/price/advisor/new/salesChannel", {params})	
		.then(response => response.data);
}

function ExportContributionMargin(params) {
	return agent
		.get()
		.post("/api/price/advisor/new/export",{params},{ responseType: "blob"})
		.then(response => response.data);
}

function getDetailAndImage(params) {
	return agent
		.get()
		.get("/api/price/advisor/getDetailAndImage", {params})	
		.then(response => response.data);
}

function getSellingPriceRate(params) {
	return agent
		.get()
		.get("/api/price/advisor/getSellingPriceRate", {params})	
		.then(response => response.data);
}

module.exports = {
	update,
	getAdvises,
	estimateProfitMargin,
	requestExportEstimation,
	getExportStatus,
	deleteEstimateRequest,
	getNewPriceAdvisorData,
	getPlatformFromPriceTable,
	ExportContributionMargin,
	getDetailAndImage,
	getSellingPriceRate
};
